import React from 'react';
import TileBoxSkeleton from '../../TileBox/skeleton';
import useCheckMobileResolution from '../../../hook/useCheckMobileResolution';

export default function GiftCardsStatisticsSkeleton() {
  const isMobile = useCheckMobileResolution();

  return (
    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', textAlign: 'center' }}>
      <div style={{ marginBottom: '20px' }}>
        <TileBoxSkeleton />
      </div>
      <TileBoxSkeleton />
    </div>
  );
}
