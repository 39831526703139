import React from 'react';
import { GiftCardsDetails } from '../../../models/GiftCard';
import TileBox from '../../TileBox';
import useCheckMobileResolution from '../../../hook/useCheckMobileResolution';

interface GiftCardsStatisticsProps {
  giftCardsDetails: GiftCardsDetails;
}

export default function GiftCardsStatistics({ giftCardsDetails }: GiftCardsStatisticsProps) {
  const isMobile = useCheckMobileResolution();
  const totalPending = giftCardsDetails.redeems.filter((r) => !r.hasBeenTransferred).length;
  const priceMultiplier = 100;

  return (
    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', textAlign: 'center' }}>
      <TileBox
        style={{
          backgroundColor: '#F0F9D7',
          border: '1px solid #D4D4D4',
          display: 'flex',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          marginBottom: '20px',
        }}
      >
        <TileBox style={isMobile ? { marginBottom: '20px' } : { marginRight: '20px' }} title="Importo totale">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ fontWeight: '600', fontSize: '24px' }}>
              {giftCardsDetails.totalRedeemed / priceMultiplier} €
            </div>
            {giftCardsDetails.pendingRedeemed > 0 ? (
              <div style={{ opacity: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ fontWeight: '600', fontSize: '20px', marginRight: '10px' }}>
                  {giftCardsDetails.pendingRedeemed / priceMultiplier} €
                </div>
                Non contabilizzati
              </div>
            ) : null}
          </div>
        </TileBox>

        <TileBox title="Gift Card riscattate">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ fontWeight: '600', fontSize: '24px' }}>{giftCardsDetails.redeems.length}</div>
            {totalPending > 0 ? (
              <div style={{ opacity: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ fontWeight: '600', fontSize: '20px', marginRight: '10px' }}>{totalPending}</div>
                Non contabilizzate
              </div>
            ) : null}
          </div>
        </TileBox>
      </TileBox>
    </div>
  );
}
