import React, { useState } from 'react';
import useCheckMobileResolution from '../../hook/useCheckMobileResolution';
import { postAmountToPayoutAndGetFeesInfoAPI, uploadStripeAccountLinkUrlAPI } from '../../services/companiesApi';
import { useParams } from 'react-router-dom';
import { CreateStripeAccountLinkResult, FeesDetails } from '../../models/Company';
import { toastSuccess, toastWarning } from '../../utils/toastMethod';
import { useAppSelector } from '../../hook/useReduxHooks';
import { RootState } from '../../store/store';
import PayoutRecap from './PayoutRecap';
import TileBox from '../TileBox';

export default function RedeemGiftCards() {
  const { companyId } = useParams<string>();
  const stipeAccountId = useAppSelector((state: RootState) => state.company.stripeAccountId);
  const stripeBalance = useAppSelector((state: RootState) => state.company.stripeBalance);
  const [show, setShow] = useState(false);
  const [feesDetails, setFeesDetails] = useState<FeesDetails>();
  const isMobile = useCheckMobileResolution();
  const priceMultiplier = 100;

  const handleUpdateStripeProfile = () => {
    uploadStripeAccountLinkUrlAPI(companyId, stipeAccountId, {
      refreshUrl: `${process.env.REACT_APP_BASE_URL}/company/${companyId}/dashboard?stripe-updated=true`,
      returnUrl: `${process.env.REACT_APP_BASE_URL}/company/${companyId}/dashboard?stripe-updated=true`,
    })
      .then((redirectUrl: CreateStripeAccountLinkResult) => {
        if (redirectUrl) {
          toastSuccess('Stai per essere reindirizzato su Stripe.');
          window.location.href = redirectUrl.url;
        }
      })
      .catch(() =>
        toastWarning("Problemi durante l'aggiornamento del tuo profilo Stripe. Riprova o contatta l'assistenza.")
      );
  };

  const handleRedirectMobileApp = () => window.open(process.env.REACT_APP_PWA_URL as string, '_blank');

  const handlePayoutClick = () => {
    postAmountToPayoutAndGetFeesInfoAPI(companyId, stipeAccountId, { amount: stripeBalance })
      .then((feesDetails: FeesDetails) => {
        setFeesDetails(feesDetails);
        setShow(true);
      })
      .catch(() => toastWarning("Problemi durante l'elaborazione della richiesta. Riprova o contatta l'assistenza."));
  };

  return (
    <>
      <TileBox
        style={{
          backgroundColor: '#F0F9D7',
          border: '1px solid #D4D4D4',
          display: 'flex',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          marginBottom: '20px',
          textAlign: isMobile ? 'center' : 'inherit',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '220px',
            height: '65px',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            marginRight: '20px',
            fontWeight: '600',
            fontSize: '32px',
          }}
        >
          {stripeBalance / priceMultiplier} €
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginRight: 'auto',
            marginTop: isMobile ? '20px' : '',
          }}
        >
          <div style={{ fontWeight: '600' }}>Trasferisci i tuoi buoni</div>
          <div>{"Premi sul pulsante 'Trasferisci' per trasferire il denaro sul tuo conto corrente."}</div>
        </div>
        <div
          style={{
            marginTop: isMobile ? '20px' : 'auto',
            marginLeft: '10px',
            display: 'flex',
            flexDirection: 'column',
            width: '215px',
          }}
        >
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginBottom: '10px' }}
            onClick={handlePayoutClick}
            disabled={stripeBalance > 0 ? false : true}
          >
            Trasferisci
          </button>
          <button type="button" className="btn btn-primary" onClick={handleUpdateStripeProfile}>
            Aggiorna profilo Stripe
          </button>
        </div>

        {isMobile ? (
          <div
            style={{
              marginTop: isMobile ? '40px' : 'auto',
              marginLeft: '10px',
              display: 'flex',
              flexDirection: 'column',
              width: '215px',
            }}
          >
            <div style={{ fontWeight: '600' }}>Vuoi riscuotere un buono?</div>
            <div style={{ marginBottom: isMobile ? '20px' : '' }}>{"Premi sul pulsante 'Apri la mobile app'."}</div>
            <button type="button" className="btn btn-primary" onClick={handleRedirectMobileApp}>
              Apri la mobile app
            </button>
          </div>
        ) : null}
      </TileBox>

      {feesDetails ? <PayoutRecap show={show} setShow={setShow} feesDetails={feesDetails} /> : null}
    </>
  );
}
