import {
  CreateStripeAccountResult,
  CreateStripeAccountLinkResult,
  PostStripeAccountLinkUrl,
  UploadLogoResult,
  PostAmountToPayout,
  FeesDetails,
} from '../models/Company';
import { toastPromise } from '../utils/toastMethod';
import api from './api';

export async function getCompaniesAPI() {
  return api.get(`/api/dashboard/companies`).then((response) => response.data);
}

export async function getCompanyDetailsAPI(companyId: string | undefined) {
  return api.get(`/api/dashboard/companies/${companyId}`).then((response) => response.data);
}

export async function uploadCompanyLogoAPI(companyId: string | undefined, form: FormData) {
  return new Promise<UploadLogoResult>((resolve, reject) => {
    api
      .post(`api/dashboard/companies/${companyId}/logo`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 10000,
      })
      .then((response) => resolve(response.data))
      .catch((err) => reject(err));
  });
}

export async function createStripeAccountAPI(companyId: string | undefined) {
  return new Promise<CreateStripeAccountResult>((resolve) => {
    toastPromise(
      api.post(`api/dashboard/companies/${companyId}/stripe/account`).then((response) => resolve(response.data)),
      'Stiamo generando il profilo attività.',
      'Operazione completata. Verrai reindirizzato su Stripe per completare la procedura.',
      "Problemi durante la creazione del profilo attività. Riprova o contatta l'assistenza."
    );
  });
}

export async function uploadStripeAccountLinkUrlAPI(
  companyId: string | undefined,
  accountId: string | undefined,
  payload: PostStripeAccountLinkUrl
) {
  return new Promise<CreateStripeAccountLinkResult>((resolve, reject) => {
    api
      .post(`/api/dashboard/companies/${companyId}/stripe/account/${accountId}/link/onboarding`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err));
  });
}

export async function getStripeAccountOnboardedResultAPI(companyId: string | undefined, accountId: string | undefined) {
  return api
    .get(`/api/dashboard/companies/${companyId}/stripe/account/${accountId}/onboarded`)
    .then((response) => response.data);
}

export async function postAmountToPayoutAndGetFeesInfoAPI(
  companyId: string | undefined,
  accountId: string | undefined,
  payload: PostAmountToPayout
) {
  return new Promise<FeesDetails>((resolve, reject) => {
    api
      .post(`/api/dashboard/companies/${companyId}/stripe/account/${accountId}/payout/fee`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err));
  });
}

export async function executePayoutAPI(
  companyId: string | undefined,
  accountId: string | undefined,
  payload: PostAmountToPayout
) {
  return new Promise((resolve) => {
    toastPromise(
      api
        .post(`api/dashboard/companies/${companyId}/stripe/account/${accountId}/payout`, payload)
        .then((response) => resolve(response.data)),
      'Stiamo eseguendo il bonifico sul tuo conto corrente.',
      'Operazione completata con successo.',
      "Problemi durante il traferimento. Riprova o contatta l'assistenza."
    );
  });
}

export async function getPayoutsDetailsAPI(companyId: string | undefined, accountId: string | undefined) {
  return api
    .get(`/api/dashboard/companies/${companyId}/stripe/account/${accountId}/payouts`)
    .then((response) => response.data);
}
