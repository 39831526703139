import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './style.css';

export default function TileBoxSkeleton() {
  return (
    <div className="tilebox-skeleton-container">
      <Skeleton height={200} />
    </div>
  );
}
