import React from 'react';
import useCheckMobileResolution from '../../hook/useCheckMobileResolution';
import TileBox from '../TileBox';

interface PayoutStatisticsProps {
  count: number;
  totalAmount: number;
}

export default function PayoutStatistics({ count, totalAmount }: PayoutStatisticsProps) {
  const isMobile = useCheckMobileResolution();
  const priceMultiplier = 100;

  return (
    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', textAlign: 'center', padding: '0px' }}>
      <TileBox
        style={{
          backgroundColor: '#F0F9D7',
          border: '1px solid #D4D4D4',
          display: 'flex',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <TileBox
          style={isMobile ? { marginBottom: '20px' } : { marginRight: '20px' }}
          title="Importo totale trasferito"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: '600',
              fontSize: '24px',
            }}
          >
            {totalAmount / priceMultiplier} €
          </div>
        </TileBox>

        <TileBox title="Trasferimenti effettuati">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: '600',
              fontSize: '24px',
            }}
          >
            {count}
          </div>
        </TileBox>
      </TileBox>
    </div>
  );
}
