import React from 'react';
import { PayoutTransaction } from '../../../models/Company';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate, formatHour } from '../../../utils/dateFormatter';
import CustomTable from '../../CustomTable';

interface PayoutsHistoryProps {
  payoutTranstactions: PayoutTransaction[];
}

export default function PayoutTransactionsHistory({ payoutTranstactions }: PayoutsHistoryProps) {
  const priceMultiplier = 100;

  const columnHelper = createColumnHelper<PayoutTransaction>();
  const columns = [
    columnHelper.accessor('executedAt', {
      header: () => 'Data esecuzione',
      cell: (executedAt) => (
        <>
          {formatDate(executedAt.getValue())} - {formatHour(executedAt.getValue())}
        </>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor('amount', {
      header: () => 'Importo trasferito',
      cell: (amount) => <>{amount.getValue() / priceMultiplier} €</>,
      enableSorting: false,
    }),
    columnHelper.accessor('fee', {
      header: () => 'Commissioni',
      cell: (fee) => <>{fee.getValue() / priceMultiplier} €</>,
      enableSorting: false,
    }),
    columnHelper.accessor('executedBy', {
      header: () => 'Eseguito da',
      cell: (executedBy) => executedBy.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('arrivalDate', {
      header: () => 'Data di arrivo prevista',
      cell: (arrivalDate) => <>{formatDate(arrivalDate.getValue())}</>,
      enableSorting: true,
    }),
  ];

  return (
    <CustomTable
      data={payoutTranstactions}
      columns={columns}
      pageStartIndex={0}
      pageMaxSize={5}
      columnsVisibility={{}}
    />
  );
}
