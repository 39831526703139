import React, { useEffect, useState } from 'react';
import { RedeemDetails } from '../../../models/GiftCard';
import { ColumnFiltersState, createColumnHelper } from '@tanstack/react-table';
import { GlobalSearch } from '../../GlobalSearch';
import { FilterContainer } from '../../Filter/FilterContainer';
import { Filter } from '../../Filter/Filter';
import { FilterBool } from '../../Filter/FilterBool';
import { formatDate, formatHour } from '../../../utils/dateFormatter';
import CustomTable from '../../CustomTable';
import TileBox from '../../TileBox';

type FilterOptions = {
  redeemedBy: string[];
  shopName: string[];
};

enum TransferredType {
  ACCOUNTED = 'Contabilizzato',
  UNACCOUNTED = 'Non contabilizzato',
}

interface GiftCardsHistoryProps {
  redeemedGiftCards: RedeemDetails[];
}

export default function GiftCardsHistory({ redeemedGiftCards }: GiftCardsHistoryProps) {
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    redeemedBy: [],
    shopName: [],
  });
  const priceMultiplier = 100;

  useEffect(() => {
    const tempFitlter: FilterOptions = { redeemedBy: [], shopName: [] };
    redeemedGiftCards.forEach((redeemed) => {
      if (!tempFitlter.redeemedBy.includes(redeemed.redeemedBy)) tempFitlter.redeemedBy.push(redeemed.redeemedBy);
      if (!tempFitlter.shopName.includes(redeemed.shopName)) tempFitlter.shopName.push(redeemed.shopName);
    });
    setFilterOptions(tempFitlter);
  }, [redeemedGiftCards]);

  const setFilter = (columnId: string, filterValue: string) => {
    console.log(columnId);
    console.log(filterValue);
    setColumnFilters((oldFilters) => {
      const updatedFilters = oldFilters.filter((filter) => filter.id !== columnId);
      const parsedValue = filterValue === 'true' ? true : filterValue === 'false' ? false : filterValue;
      if (filterValue) updatedFilters.push({ id: columnId, value: parsedValue });
      return updatedFilters;
    });
  };

  const columnHelper = createColumnHelper<RedeemDetails>();
  const columns = [
    columnHelper.accessor('redeemedAt', {
      header: () => 'Data',
      cell: (redeemedAt) => (
        <>
          {formatDate(redeemedAt.getValue())} - {formatHour(redeemedAt.getValue())}
        </>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor('redeemedBy', {
      header: () => 'Riscosso da',
      cell: (redeemedBy) => redeemedBy.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('shopName', {
      header: () => 'Negozio',
      cell: (shopName) => shopName.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('amount', {
      header: () => 'Valore',
      cell: (amount) => <>{amount.getValue() / priceMultiplier} €</>,
      enableSorting: false,
    }),
    columnHelper.accessor('hasBeenTransferred', {
      header: () => 'Stato',
      cell: (hasBeenTransferred) => (
        <>{hasBeenTransferred.getValue() ? TransferredType.ACCOUNTED : TransferredType.UNACCOUNTED}</>
      ),
      enableSorting: false,
    }),
  ];

  return (
    <TileBox title="Lista Gift Card riscattate">
      <>
        {redeemedGiftCards.length !== 0 ? (
          <>
            <GlobalSearch filterValue={globalFilter} onChange={(value) => setGlobalFilter(value)} />
            <FilterContainer>
              <Filter
                columnId="redeemedBy"
                options={filterOptions.redeemedBy}
                placeholder="Riscosso da"
                setFilter={setFilter}
              />
              <Filter
                columnId="shopName"
                options={filterOptions.shopName}
                placeholder="Negozio"
                setFilter={setFilter}
              />
              <FilterBool
                columnId="hasBeenTransferred"
                placeholder="Trasferibile"
                trueLabel={TransferredType.ACCOUNTED}
                falseLabel={TransferredType.UNACCOUNTED}
                setFilter={setFilter}
              />
            </FilterContainer>
            <CustomTable
              data={redeemedGiftCards}
              columns={columns}
              pageStartIndex={0}
              pageMaxSize={5}
              columnsVisibility={{}}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
            />
          </>
        ) : (
          <div>Non ci sono Gift Card riscosse al momento.</div>
        )}
      </>
    </TileBox>
  );
}
