import React, { useEffect, useState } from 'react';
import { GiftCardsDetails } from '../models/GiftCard';
import { getRedeemGiftCardsDetailsAPI } from '../services/giftCardApi';
import { useParams } from 'react-router-dom';
import { toastWarning } from '../utils/toastMethod';
import PageLayout from '../components/Layout/PageLayout';
import GiftCardsHistory from '../components/GiftCardPage/History';
import GiftCardsHistorySkeleton from '../components/GiftCardPage/History/skeleton';
import GiftCardsStatistics from '../components/GiftCardPage/Statistics';
import GiftCardsStatisticsSkeleton from '../components/GiftCardPage/Statistics/skeleton';

export default function GiftCardPage() {
  const { companyId } = useParams<string>();
  const [giftCardsRedeem, setGiftCardsRedeem] = useState<GiftCardsDetails>();

  useEffect(() => {
    getRedeemGiftCardsDetailsAPI(companyId)
      .then((result) => setGiftCardsRedeem(result))
      .catch(() => toastWarning('Errore nel carimento delle informazioni relative alle GiftCard. Riprova!'));
  }, [companyId]);

  return (
    <PageLayout
      titlePage="Gift Card"
      subtitlePage="In questa pagina trovi un resoconto relativo alle Gift Card riscattate nei nogozi della tua attività."
    >
      {giftCardsRedeem ? (
        <>
          <GiftCardsStatistics giftCardsDetails={giftCardsRedeem} />
          <GiftCardsHistory redeemedGiftCards={giftCardsRedeem.redeems} />
        </>
      ) : (
        <>
          <GiftCardsStatisticsSkeleton />
          <GiftCardsHistorySkeleton />
        </>
      )}
    </PageLayout>
  );
}
