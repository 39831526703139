import React, { ReactNode } from 'react';
import './style.css';

interface TileBoxProps {
  style?: React.CSSProperties;
  title?: string;
  children: ReactNode;
}

export default function TileBox({ style, title, children }: TileBoxProps) {
  return (
    <div style={style} className="tilebox-container">
      {title ? <label style={{ color: '#004159', fontWeight: '600', marginBottom: '20px' }}>{title}</label> : null}
      {children}
    </div>
  );
}
