import React, { useEffect } from 'react';
import { getPayoutsDetailsAPI } from '../../services/companiesApi';
import { CompanyDetails, PayoutDetails } from '../../models/Company';
import { useAppSelector } from '../../hook/useReduxHooks';
import { RootState } from '../../store/store';
import { toastWarning } from '../../utils/toastMethod';
import RedeemGiftCards from './RedeemGiftCards';
import PayoutTransactionsHistory from './PayoutTransactionsHistory';
import PayoutTransactionsSkeleton from './PayoutTransactionsHistory/skeleton';
import PayoutStatistics from './PayoutStatistics';
import TileBox from '../TileBox';

export default function DashboardInfo() {
  const companyDetails: CompanyDetails = useAppSelector((state: RootState) => state.company);
  const [payoutDetails, setPayoutDetails] = React.useState<PayoutDetails>();

  useEffect(() => {
    getPayoutsDetailsAPI(companyDetails.id, companyDetails.stripeAccountId)
      .then((payoutDetails: PayoutDetails) => setPayoutDetails(payoutDetails))
      .catch(() =>
        toastWarning('Errore nel carimento delle informazioni relative ai trasferimenti. Prova a ricaricare la pagina.')
      );
  }, [companyDetails.id, companyDetails.stripeAccountId]);

  return (
    <>
      <div className="row">
        <RedeemGiftCards />
      </div>
      {payoutDetails ? (
        <TileBox title="Storico trasferimenti">
          <div className="row">
            <PayoutStatistics count={payoutDetails.count} totalAmount={payoutDetails.totalAmount} />
          </div>
          {payoutDetails.transactions.length > 0 ? (
            <div className="row" style={{ marginTop: '20px' }}>
              <PayoutTransactionsHistory payoutTranstactions={payoutDetails.transactions} />
            </div>
          ) : null}
        </TileBox>
      ) : (
        <div className="row">
          <PayoutTransactionsSkeleton />
        </div>
      )}
    </>
  );
}
