import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FeesDetails } from '../../models/Company';
import { CustomTooltip } from '../CustomTooltip';
import { executePayoutAPI } from '../../services/companiesApi';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hook/useReduxHooks';
import { RootState } from '../../store/store';
import { toastSuccess, toastWarning } from '../../utils/toastMethod';
import { resetStripeBalance } from '../../store/companySlice';
import { useDispatch } from 'react-redux';
import questionCircleIcon from '../../assets/images/question-circle-icon.svg';

interface PayoutRecapProps {
  show: boolean;
  setShow: (value: boolean) => void;
  feesDetails: FeesDetails;
}

export default function PayoutRecap({ show, setShow, feesDetails }: PayoutRecapProps) {
  const dispatch = useDispatch();
  const { companyId } = useParams<string>();
  const stipeAccountId = useAppSelector((state: RootState) => state.company.stripeAccountId);
  const [disableTransferButton, setDisableTransferButton] = useState(false);
  const priceMultiplier = 100;

  const handleHide = () => setShow(false);

  const hanldeClick = () => {
    setDisableTransferButton(true);
    executePayoutAPI(companyId, stipeAccountId, {
      amount: feesDetails.amountToPayout + feesDetails.fee + feesDetails.monthlyFee,
    })
      .then(() => {
        dispatch(resetStripeBalance());
        toastSuccess('Il denaro è stato trasferito sul tuo conto corrente.');
        setShow(false);
      })
      .catch(() => toastWarning("Problemi durante il traferimento. Riprova o contatta l'assistenza."))
      .finally(() => setDisableTransferButton(false));
  };

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Trasferisci il denaro sul tuo conto corrente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div style={{ fontWeight: '600', marginBottom: '10px' }}>Ecco il tuo riepilogo</div>
          <div className="d-flex justify-content-between mb-2">
            <div>
              <span style={{ marginRight: '5px' }}>Commissioni</span>
              <img
                style={{ width: '15px', opacity: '0.8' }}
                data-tooltip-id="variable-fees"
                src={questionCircleIcon}
                alt="questionCircleIcon"
              />
            </div>
            <CustomTooltip id="variable-fees">
              Le commissioni sono date dalla somma delle seguenti voci:
              <ul>
                <li>Costi fissi: 2.10 € per il primo bonifico del mese, 0.10 € per i successivi.</li>
                <li>Costi variabili: 0.25% dell&apos;importo trasferito.</li>
              </ul>
            </CustomTooltip>
            <span>{((feesDetails.fee + feesDetails.monthlyFee) / priceMultiplier).toFixed(2)} €</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Importo totale trasferito sul tuo conto corrente</span>
            <strong>{(feesDetails.amountToPayout / priceMultiplier).toFixed(2)} €</strong>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" disabled={disableTransferButton} onClick={hanldeClick}>
          Trasferisci
        </button>
      </Modal.Footer>
    </Modal>
  );
}
